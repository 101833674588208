import React from "react"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Ebook = (data) => {
  let ebookHtml = '';
  ebookHtml += '<h1>'+data.pathContext.data.title+'</h1>';
  if (data.pathContext.data.units) {
    data.pathContext.data.units.forEach((unit) => {
        if (unit.body) {
          ebookHtml += documentToHtmlString(JSON.parse(unit.body.internal.content));
          if (unit.modules) {
            unit.modules.forEach((module) => {
                if (module.body) {
                    ebookHtml += documentToHtmlString(JSON.parse(module.body.internal.content));
                }
                if (module.sections) {
                    module.sections.forEach((section) => {
                        if (section.body) {
                            ebookHtml += documentToHtmlString(JSON.parse(section.body.internal.content));
                        }
                    });
                    }
                })
            }
        }
    });
  }

    return <div style={{
        maxWidth: '960px',
        margin: '20px auto',
        padding:'0 40px'
    }} dangerouslySetInnerHTML={{ __html: ebookHtml }}></div>
}

export default Ebook
